import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import BarChart from "./charts/BarChart";
import AreaChart from "./charts/AreaChart";
import { createPdfWithTemplateAndContent } from "../../../../utils/generatePdf";
import { Bars } from "react-loader-spinner";
import NoteForm from "./NoteForm";
import Note from "./note";
import { useSelector } from "react-redux";

const Ga = ({
  onSubmitNote,
  ga_insight,
  title,
  subTitle,
  dates,
  fetchIaIterpretationGa,
  lastNote,
}) => {
  const suggestion = useSelector((state) => state.clientReducer.suggestions.ga);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadPdf = async (area) => {
    setIsLoading(true);

    const selector = `[area-number][aria="${area}"]`;
    const areas = Array.from(document.querySelectorAll(selector))
      .sort(
        (a, b) =>
          parseInt(a.getAttribute("area-number")) -
          parseInt(b.getAttribute("area-number"))
      )
      .map((el) => el.id);

    await createPdfWithTemplateAndContent("/template.pdf", areas, title, subTitle, dates);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <Row>
        <Col md={12}>
          <button onClick={() => handleDownloadPdf("ga")} className="prodotop-link mb-3">
            Télécharger le rapport PDF
          </button>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="1"
            id="ga-area1"
            data-width="380"
          >
            <div className="card-header">
              <h6>Sessions :</h6>
              <p className="text-sm broun-text mb-0">
                Période au cours de laquelle un utilisateur est actif sur votre site.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4285F4"
                      dataType="sessions"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="sessions"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="2"
            id="ga-area2"
            data-width="380"
          >
            <div className="card-header">
              <h6>Sessions avec engagement :</h6>
              <p className="text-sm broun-text mb-0">
                Nombre de sessions avec engagement.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#DB4437"
                      dataType="engagedSessions"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#DB4437"
                      dataType="engagedSessions"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="3"
            id="ga-area3"
            data-width="380"
          >
            <div className="card-header">
              <h6>Pages par sessions :</h6>
              <p className="text-sm broun-text mb-0">
                Nombre moyen de pages vues au cours d'une session par un utilisateur.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#F4B400"
                      dataType="pageviewsPerSession"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#F4B400"
                      dataType="pageviewsPerSession"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="4"
            id="ga-area4"
            data-width="380"
          >
            <div className="card-header">
              <h6>Nombre total d'utilisateurs :</h6>
              <p className="text-sm broun-text mb-0">
                Nombre d'utilisateurs uniques qui ont visité le site internet.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#0F9D58"
                      dataType="totalUsers"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#0F9D58"
                      dataType="totalUsers"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="5"
            id="ga-area5"
            data-width="380"
          >
            <div className="card-header">
              <h6>Nouveaux utilisateurs :</h6>
              <p className="text-sm broun-text mb-0">
                Nombre de nouveaux utilisateurs qui ont visité le site.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4285F4"
                      dataType="newUsers"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="newUsers"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="6"
            id="ga-area6"
            data-width="380"
          >
            <div className="card-header">
              <h6>Durée moyenne de la session :</h6>
              <p className="text-sm broun-text mb-0">
                Il s'agit de la durée moyenne d'une session.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#DB4437"
                      dataType="averageSessionDuration"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#DB4437"
                      dataType="averageSessionDuration"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="7"
            id="ga-area7"
            data-width="380"
          >
            <div className="card-header">
              <h6>Taux d'engagement %:</h6>
              <p className="text-sm broun-text mb-0">
                Taux d'utilisateurs ayant eu de l'engagement sur le site.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>

                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#F4B400"
                      dataType="engagementRate"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#F4B400"
                      dataType="engagementRate"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="8"
            id="ga-area8"
            data-width="380"
          >
            <div className="card-header">
              <h6>Taux de rebond %:</h6>
              <p className="text-sm broun-text mb-0">
                Pourcentage de sessions qui visitent une seule page et ne font aucune
                interaction.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>

                  {ga_insight.periode === "monthly" ? (
                    <BarChart
                      color="#0F9D58"
                      dataType="bounceRate"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  ) : (
                    <AreaChart
                      color="#0F9D58"
                      dataType="bounceRate"
                      data={ga_insight.processedData}
                      width={600}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={12}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="9"
            id="ga-area9"
            data-width="550"
          >
            <div className="card-header">
              <h6>Pages les plus visitées:</h6>
              <p className="text-sm broun-text mb-0"></p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}>
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Page de destination
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                          Sessions
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                          Utilisateurs
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                          Taux de rebond
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ga_insight.topPages.map((tp, index) => (
                        <tr key={index}>
                          <td>{tp.page.slice(0, 50)}</td>
                          <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                            {tp.sessions}
                          </td>
                          <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                            {tp.totalUsers}
                          </td>
                          <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                            {Number(parseFloat(tp.bounceRate * 100).toFixed(1))}%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={12}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="ga"
            area-number="10"
            id="ga-area10"
            data-width="550"
          >
            <div className="card-header">
              <h6>Événements:</h6>
              <p className="text-sm broun-text mb-0"></p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}>
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Nom de l'événement
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                          Nombre d'événements
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ga_insight.eventsData.map((ed, index) => (
                        <tr key={index}>
                          <td>{ed.event}</td>
                          <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                            {ed.eventCount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Note note={lastNote} aria="ga" areaNumber="11" id="area11" />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <NoteForm
            defaultTitle={`Rapport - ${subTitle}`}
            plateform="ga"
            onSubmitNote={onSubmitNote}
            suggestion={suggestion}
            fetchIaIterpretation={fetchIaIterpretationGa}
          />
        </Col>
      </Row>
    </>
  );
};

export default Ga;
