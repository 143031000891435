// generatePdf.js
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";

export async function addElementAsImageToPdf(pdfDoc, elementId, yPos, page) {
  const element = document.getElementById(elementId);
  if (!element) return;

  const canvas = await html2canvas(element);

  const dataUri = canvas.toDataURL("image/png");
  const data = dataUri.split(",")[1];
  const bytes = window.atob(data);
  const buf = new ArrayBuffer(bytes.length);
  const byteArr = new Uint8Array(buf);

  for (let i = 0; i < bytes.length; i++) {
    byteArr[i] = bytes.charCodeAt(i);
  }

  const pngImage = await pdfDoc.embedPng(byteArr);

  const width = parseInt(element.getAttribute("data-width"), 10) || 380;

  const scale = width / pngImage.width;
  const imageHeight = pngImage.height * scale;

  let newPageAdded = false;

  if (yPos + imageHeight > page.getHeight()) {
    const [newTemplatePage] = await pdfDoc.copyPages(pdfDoc, [1]);
    pdfDoc.addPage(newTemplatePage);
    page = newTemplatePage;
    yPos = 60;
    newPageAdded = true;
  }

  console.log("--------");
  console.log(element.getAttribute("area-number"));
  console.log("yPos", yPos);
  console.log("imageHeight", imageHeight);
  console.log("pageHeight", page.getHeight());

  console.log("pageCount : ", page.doc.pageCount);
  console.log("Y : ", page.getHeight() - yPos - imageHeight - 20);
  console.log("-------");

  page.drawImage(pngImage, {
    x: 20,
    y: page.getHeight() - yPos - imageHeight - 20,
    width: width,
    height: imageHeight,
  });

  return { yPos: yPos + imageHeight, page, newPageAdded };
}

export const createPdfWithTemplateAndContent = async (
  templateUrl,
  elementIds,
  title,
  subTitle,
  dates
) => {
  const existingPdfBytes = await fetch(templateUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  if (pdfDoc.getPageCount() < 2) {
    throw new Error("Le template PDF n'a pas suffisamment de pages.");
  }

  let currentPageIndex = 1;
  if (pdfDoc.getPageCount() > 1) {
    for (let i = currentPageIndex; i < pdfDoc.getPageCount(); i++) {
      const page = pdfDoc.getPage(i);
      addTextToPage(page, title, 18, 210, 30);
      addTextToPage(page, subTitle, 10, 210, 45);
      addTextToPage(page, dates, 8, 210, 60);
    }
  }

  const [templatePage] = await pdfDoc.copyPages(pdfDoc, [1]);
  pdfDoc.addPage(templatePage);

  let currentPage = pdfDoc.getPages().length - 1;
  let yPos = 60;

  for (const elementId of elementIds) {
    const page = pdfDoc.getPages()[currentPage];
    let result = await addElementAsImageToPdf(pdfDoc, elementId, yPos, page);
    yPos = result.yPos;

    if (result.newPageAdded) {
      currentPage += 1;
      // yPos = 60;
    }
  }

  if (pdfDoc.getPageCount() > 2) {
    pdfDoc.removePage(1);
  }

  if (pdfDoc.getPageCount() >= 1) {
    const firstPage = pdfDoc.getPage(0);
    addTextToPage(firstPage, title, 25, 383, 140);
    addTextToPage(firstPage, subTitle, 15, 383, 155);
    addTextToPage(firstPage, dates, 10, 383, 170);
  }

  const pdfBytes = await pdfDoc.save();
  download(pdfBytes, title + "-" + subTitle + ".pdf", "application/pdf");
};

function addTextToPage(page, text, fontSize, x, y) {
  const { width, height } = page.getSize();

  page.drawText(text, {
    x: width - x,
    y: height - y,
    size: fontSize,
  });
}

export const download = (blob, filename, mime) => {
  const blobUrl = URL.createObjectURL(new Blob([blob], { type: mime }));
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl);
};
