import axios from "axios";

const API_URL = "/api/calendar";

const getCalendarInfo = (actAsData) => {
  return axios.post(API_URL, actAsData).then((response) => {
    return response.data;
  });
};
const getTodos = (actAsData) => {
  return axios.post(`${API_URL}/todos`, actAsData).then((response) => {
    return response.data;
  });
};

const createTask = (data) => {
  return axios.post(`${API_URL}/${data.orderId}/new_todo`, data).then((response) => {
    return response.data;
  });
};

const updateTodoEvent = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteTask = (id) => {
  return axios.put(`${API_URL}/todos/${id}`, { archived: true }).then((response) => {
    return response.data;
  });
};

const undragTask = (id) => {
  return axios.put(`${API_URL}/todos/${id}`, { dragged: false }).then((response) => {
    return response.data;
  });
};

const unpdateTask = (id, data) => {
  return axios.put(`${API_URL}/todos/${id}`, data).then((response) => {
    return response.data;
  });
};

const devideTask = (taskId, data) => {
  return axios.put(`${API_URL}/${taskId}/devide`, data).then((response) => {
    return response.data;
  });
};

const duplicateTask = (taskId) => {
  return axios.post(`${API_URL}/${taskId}/duplicate`).then((response) => {
    return response.data;
  });
};

export const calendarService = {
  getCalendarInfo,
  updateTodoEvent,
  getTodos,
  devideTask,
  createTask,
  deleteTask,
  undragTask,
  duplicateTask,
  unpdateTask,
};
