import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import BarChart from "./charts/BarChart";
import ShapeBar from "./charts/ShapeBar";
import PieChart from "./charts/PieChart";
import AreaChart from "./charts/AreaChart";

import NoteForm from "./NoteForm";
import Note from "./note";
import { createPdfWithTemplateAndContent } from "../../../../utils/generatePdf";
import { Bars } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Fads = ({
  onSubmitNote,
  fb_insight,
  title,
  subTitle,
  dates,
  fetchIaIterpretationFads,
  lastNote,
}) => {
  const suggestion = useSelector((state) => state.clientReducer.suggestions.fads);
  const [isLoading, setIsLoading] = useState(false);
  const handleDownloadPdf = async (area) => {
    setIsLoading(true);

    const selector = `[area-number][aria="${area}"]`;
    const areas = Array.from(document.querySelectorAll(selector))
      .sort(
        (a, b) =>
          parseInt(a.getAttribute("area-number")) -
          parseInt(b.getAttribute("area-number"))
      )
      .map((el) => el.id);

    await createPdfWithTemplateAndContent("/template.pdf", areas, title, subTitle, dates);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <Row>
        <Col md={12}>
          <button
            onClick={() => handleDownloadPdf("fads")}
            className="prodotop-link mb-3"
          >
            Télécharger le rapport PDF
          </button>
        </Col>
      </Row>
      <div
        className="card medium-card mb-4 pb-2 purple-border bg-white"
        aria="fads"
        area-number="1"
        id="area1"
        data-width="680"
      >
        <div className="card-header">
          <h6>
            Impression : {fb_insight.lastMounthData[0].impressions}{" "}
            <small className="text-uppercase  text-xs font-weight-bolder  broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Les impressions représentent le nombre total de fois où votre publicité a été
            affichée.
          </p>
        </div>
        <div className="card-body py-0">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="impressions"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="impressions"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Âge</small>
              <ShapeBar info="age" dataType="impressions" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Genre</small>
              <PieChart
                info="gender"
                dataType="impressions"
                data={fb_insight.genderData}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="card medium-card mb-4 pb-2 purple-border bg-white"
        aria="fads"
        area-number="2"
        id="area2"
        data-width="680"
      >
        <div className="card-header">
          <h6>
            Couverture : {fb_insight.lastMounthData[0].reach}{" "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            La portée (Reach) indique le nombre de personnes uniques qui ont vu votre
            publicité.
          </p>
        </div>
        <div className="card-body py-0">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="reach"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="reach"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="reach" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="reach" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="card medium-card mb-4 pb-2 purple-border bg-white"
        aria="fads"
        area-number="3"
        id="area3"
        data-width="680"
      >
        <div className="card-header">
          <h6>
            Clics : {fb_insight.lastMounthData[0].clicks}{" "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Les clics représentent le nombre total de fois où les utilisateurs ont
            interagi avec votre publicité.
          </p>
        </div>
        <div className="card-body py-0">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="clicks"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="clicks"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="clicks" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="clicks" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col md={6}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="fads"
            area-number="4"
            id="area4"
            data-width="380"
          >
            <div className="card-header">
              <h6>
                Fréquence :{" "}
                {Number(parseFloat(fb_insight.lastMounthData[0].frequency).toFixed(2))}{" "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                La fréquence représente le nombre moyen de fois qu'un individu a vu votre
                publicité durant la période spécifiée.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={4}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {fb_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4267B2"
                      dataType="frequency"
                      data={fb_insight.historicalData}
                    />
                  ) : (
                    <AreaChart
                      color="#4267B2"
                      dataType="frequency"
                      data={fb_insight.historicalData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {/* CPC Section */}
          <div
            className="card medium-card mb-4 pb-2 purple-border bg-white"
            aria="fads"
            area-number="5"
            id="area5"
            data-width="380"
          >
            <div className="card-header">
              <h6>
                CPC moyen:{" "}
                {Number(parseFloat(fb_insight.lastMounthData[0].cpc).toFixed(3))}
                {"€ "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Le coût par clic (CPC) représente le coût moyen payé pour chaque clic reçu
                sur votre publicité.
              </p>
            </div>
            <div className="card-body py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={4}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {fb_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4267B2"
                      dataType="cpc"
                      data={fb_insight.historicalData}
                    />
                  ) : (
                    <AreaChart
                      color="#4267B2"
                      dataType="cpc"
                      data={fb_insight.historicalData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {/* CTR Section */}
      <div
        className="card medium-card mb-4 pb-2 purple-border bg-white"
        aria="fads"
        area-number="6"
        id="area6"
        data-width="680"
      >
        <div className="card-header">
          <h6>
            CTR : {Number(parseFloat(fb_insight.lastMounthData[0].ctr).toFixed(2))}
            {"% "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Le taux de clics (CTR) est le pourcentage de clics reçus par rapport au nombre
            d'impressions.
          </p>
        </div>
        <div className="card-body py-0">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="ctr"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="ctr"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="ctr" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="ctr" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col>
          <Note note={lastNote} aria="fads" areaNumber="7" id="area7" />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <NoteForm
            defaultTitle={`Rapport - ${subTitle}`}
            plateform="fads"
            onSubmitNote={onSubmitNote}
            suggestion={suggestion}
            fetchIaIterpretation={fetchIaIterpretationFads}
          />
        </Col>
      </Row>
    </>
  );
};

export default Fads;
