import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Offcanvas } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  fetchCalendarInfo,
  fetchTodosInfo,
  updateTodoEvent,
  devideTask,
  createTask,
  deleteTask,
  duplicateTask,
  undragTask,
  unpdateTask,
  addToDraggedEvents,
  removeFromUndraggedEvents,
} from "../../../redux/actions/calendarActions";

import {
  convertMinutesToHoursAndMinutes,
  getMinutesDifference,
  dayPropGetter,
  eventPropGetter,
} from "../../utils/calendar";

import { fetchTinyClients } from "../../../redux/actions/clientActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { fetchUsers } from "../../../redux/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Chart from "./chart";
import TaskForm from "./TaskForm";
import Filter from "./Filter";
import EventAction from "./eventAction";
import UnDraggedEvents from "./unDraggedEvents";
import { NavContext } from "../../NavContext";
import { useParams } from "react-router-dom";
import { useWindowDimensions } from "./useWindowDimensions";
import { AnimatePresence } from "framer-motion";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const MyCalendar = () => {
  const dispatch = useDispatch();
  const { newTicket } = useParams();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const [showFilter, setShowFilter] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventAction, setEventAction] = useState({ show: false, event: null });
  const [calendarDates, setCalendarDates] = useState(false);
  const { height, width } = useWindowDimensions();
  const [dates, setDates] = useState({
    start: moment().startOf("week"),
    end: moment().endOf("week"),
  });
  const [draggedEvents, setDraggedEvents] = useState([]);
  const [undraggedEvents, setUndraggedEvents] = useState([]);
  const [productList, setProductList] = useState([]);
  const [clientName, setClientName] = useState(false);
  const [view, setView] = useState("work_week");
  const [calendarStep, setCalendarStep] = useState(5);
  const { activeNav, setActiveNav } = useContext(NavContext);
  const [weekDates, setWeekDates] = useState({
    start: moment().startOf("week")._d,
    end: moment().endOf("week")._d,
  });
  const [draggedEvent, setDraggedEvent] = useState(false);
  const clientId = watch("clientId");
  const productId = watch("productId");
  const calendarInfo = useSelector((state) => state.calendarReducer.calendarInfo);
  const undragged_tasks = useSelector((state) => state.calendarReducer.undragged_tasks);
  const loading = useSelector((state) => state.calendarReducer.loading);
  const error = useSelector((state) => state.calendarReducer.error);
  const users = useSelector((state) => state.userReducer.users);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const clients = useSelector((state) => state.clientReducer.tiny_clients);
  const products = useSelector((state) => state.productReducer.products);

  const isFetchingClients = useSelector((state) => state.clientReducer.isFetching);

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";
  const AUTHORIZED_MANAGER = MANAGER && currentUser.departement === "Team1";
  const can_manage_calendar =
    AUTHORIZED_MANAGER ||
    (currentUser.can_resize && currentUser._id === getValues("actAs"));

  const allowedStartTime = new Date();
  allowedStartTime.setHours(8, 0, 0, 0);
  const allowedEndTime = new Date();
  allowedEndTime.setHours(19, 0, 0, 0);

  const clientList = clients
    ? clients.map((option) => ({
        value: option._id,
        label: option?.companyName,
      }))
    : [];

  useEffect(() => {
    document.title = "ProdOTop - Calendrier";
  }, []);

  // THIS SHOULD BE REPLACED WITH SHARE_CALENDAR
  const filtred_users = users.filter(
    (user) => user.role !== "boss" && user.role !== "client"
  );

  const calendarUsers = users.filter(
    (user) =>
      user.role === "employee" &&
      user.departement === currentUser.departement &&
      (BOSS || AUTHORIZED_MANAGER || user._id === currentUser._id)
  );

  useEffect(() => {
    setActiveNav("calendar");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(fetchTinyClients());
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    setValue("actAs", currentUser.role === "manager" ? "manager" : currentUser._id);
  }, []);

  useEffect(() => {
    if (currentUser && dates) {
      dispatch(
        fetchCalendarInfo({
          actAs: getValues("actAs")
            ? getValues("actAs")
            : currentUser.role === "manager"
            ? "manager"
            : currentUser._id,
          dates: dates,
        })
      );
      dispatch(
        fetchTodosInfo({
          actAs: getValues("actAs")
            ? getValues("actAs")
            : currentUser.role === "manager"
            ? "manager"
            : currentUser._id,
        })
      );
    }
  }, [dates]);

  useEffect(() => {
    setDates({
      start: moment().startOf(view === "work_week" ? "week" : "month")._d,
      end: moment().endOf(view === "work_week" ? "week" : "month")._d,
    });
  }, [view]);

  useEffect(() => {
    if (calendarInfo) {
      const updatedDraggedTasks = calendarInfo?.dragged_tasks?.map((event) => {
        const updatedEvent = { ...event };
        updatedEvent.start = moment(event.start)._d;
        updatedEvent.end = moment(event.end)._d;
        return updatedEvent;
      });

      setDraggedEvents(updatedDraggedTasks);
      setUndraggedEvents(undragged_tasks);
    }
  }, [calendarInfo, undragged_tasks]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    // charger les produits dans le formulaire de filtre
    if (clientId) {
      const client = clients.filter((cl) => cl._id === clientId.value);
      const productIds = client.reduce((ids, client) => {
        client.orders.forEach((order) => {
          ids.add(order.product);
        });
        return ids;
      }, new Set());

      setProductList(
        products
          .filter((product) => productIds.has(product._id))
          .map((option) => ({
            value: option._id,
            label: option.name,
          }))
      );
    } else {
      setProductList(
        products.map((option) => ({
          value: option._id,
          label: option.name,
        }))
      );
    }
  }, [products, clientId, setProductList]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // CALENDAR functions
  const formatName = (task, id) => `${task}`;
  const customOnDragOver = (event) => {
    if (draggedEvent !== "undroppable") {
      event.preventDefault();
    }
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    console.log("onDropFromOutside");
    const minutes = draggedEvent.minutes;
    const durationInMilliseconds = minutes * 60 * 1000;

    if (view === "month") {
      start = new Date(start.setHours(10, 0, 0, 0));
    }
    const newEndDate = new Date(start.getTime() + durationInMilliseconds);

    const event = {
      _id: draggedEvent._id,
      task: formatName(draggedEvent.task, draggedEvent._id),
      start,
      end: newEndDate,
      isAllDay: false,
      order: draggedEvent.order,
      minutes: minutes,
      client: draggedEvent.client,
      flexibility: draggedEvent.flexibility,
      planned: draggedEvent.planned,
    };

    setDraggedEvent(null);
    newEvent(event);
  };
  const newEvent = (event) => {
    console.log("newEvent", "From undragged to dragged");

    let new_dragged_event = {
      _id: event._id,
      task: event.task,
      allDay: false,
      start: event.start,
      end: event.end,
      order: event.order,
      minutes: event.minutes,
      client: event.client,
      planned: event.planned,
    };

    // Add to dragged events
    dispatch(addToDraggedEvents(new_dragged_event));

    // remove from undragged
    dispatch(removeFromUndraggedEvents(event._id));

    dispatch(updateTodoEvent(event._id, { start: event.start, end: event.end }));
  };
  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    console.log("moveEvent", event._id);
    const idx = draggedEvents.indexOf(event);

    const updatedEvent = { ...event, start, end, allDay: false };

    const nextEvents = [...draggedEvents];
    nextEvents.splice(idx, 1, updatedEvent);

    setDraggedEvents(nextEvents);
    dispatch(updateTodoEvent(event._id, { start: start, end: end }));
  };
  const resizeEvent = ({ event, start, end }) => {
    console.log("resizeEvent", event._id);
    const minutesDifference = getMinutesDifference(start, end);

    const minutes = minutesDifference > 480 ? event.minutes : minutesDifference;
    const EndDate =
      minutesDifference > 480 ? new Date(start.getTime() + minutes * 60 * 1000) : end;

    const nextEvents = draggedEvents.map((existingEvent) => {
      return existingEvent._id == event._id
        ? {
            ...existingEvent,
            start: start,
            end: EndDate,
            minutes: minutes,
          }
        : existingEvent;
    });

    setDraggedEvents(nextEvents);

    dispatch(
      updateTodoEvent(event._id, {
        start,
        end,
        minutes,
      })
    );
  };
  const handleDrop = (event) => {
    event.preventDefault();

    const data = event.dataTransfer.getData("text/plain");
    const draggedEvent = JSON.parse(data);
    setDraggedEvent(draggedEvent);
  };

  // Calendar Filter
  const onSubmitFilter = (data) => {
    setShowFilter(false);
    if (data.clientId) {
      setShowGraphs(true);
      setClientName(data.clientId.label);
    }
    dispatch(fetchCalendarInfo({ ...data, dates: dates }));
    dispatch(fetchTodosInfo(data));
  };

  // ----
  const week_work = (userId) => {
    const filteredEvents = draggedEvents.filter(
      (event) =>
        (event.order.team1Responsible === userId ||
          event.order.team4Responsible === userId) &&
        event.start.valueOf() >= weekDates.start.valueOf() &&
        event.end.valueOf() < weekDates.end.valueOf()
    );

    const totalMinutes = filteredEvents.reduce(
      (total, event) => total + event.minutes,
      0
    );

    return convertMinutesToHoursAndMinutes(totalMinutes);
  };

  // ----  Change TODO Done status
  const onDoneTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    handleDoneTodo(event);
  };

  const handleDoneTodo = (event) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              done: !event.done,
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });
      dispatch(unpdateTask(event._id, { done: !event.done }));
    }
  };
  // ---- Change TODO name, flexibility, url
  const onChangeTask = (event, task, flexibility) => {
    setEventAction({ ...eventAction, show: false });
    handleChangeTask(event, task, flexibility);
  };
  const handleChangeTask = (event, task, flexibility) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              task,
              flexibility,
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });
      dispatch(unpdateTask(event._id, { task, flexibility }));
    }
  };

  // ---- Change TODO shared status
  const onShareTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    handleShareTodo(event);
  };
  const handleShareTodo = (event) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              shared: !event.shared,
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });
      dispatch(unpdateTask(event._id, { shared: !event.shared }));
    }
  };

  // ---- undragTask
  const onUndragTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    dispatch(undragTask(event));
  };

  // ---- Duplicate Todo
  const onDuplicateTodo = (id) => {
    setEventAction({ ...eventAction, show: false });
    dispatch(duplicateTask(id));
  };

  // ---- Delete Todo
  const onDeleteTodo = (id) => {
    setEventAction({ ...eventAction, show: false });
    dispatch(deleteTask(id));
  };

  // ---- Start Todo
  const onStartTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    handleStartEvent(event);
  };
  const handleStartEvent = (event) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              start: moment()._d,
              end: moment().add(event.minutes, "minutes")._d,
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });
      dispatch(
        updateTodoEvent(event._id, {
          start: moment()._d,
          end: moment().add(event.minutes, "minutes")._d,
        })
      );
    }
  };

  // ---- End Todo
  const onEndTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    handleEndEvent(event);
  };
  const handleEndEvent = (event) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              end: moment()._d,
              minutes: getMinutesDifference(event.start, moment()._d),
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });

      dispatch(
        updateTodoEvent(event._id, {
          end: moment()._d,
          minutes: getMinutesDifference(event.start, moment()._d),
        })
      );
    }
  };

  // ---- Cut Todo
  const onCutTodo = (event) => {
    setEventAction({ ...eventAction, show: false });
    handleCutEvent(event);
  };
  const handleCutEvent = (event) => {
    if (can_manage_calendar) {
      setDraggedEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) => {
          if (event._id === prevEvent._id) {
            return {
              ...prevEvent,
              end: moment()._d,
              minutes: getMinutesDifference(event.start, moment()._d),
            };
          }
          return prevEvent;
        });

        return updatedEvents;
      });

      dispatch(
        updateTodoEvent(event._id, {
          end: moment()._d,
          minutes: getMinutesDifference(event.start, moment()._d),
        })
      );

      //add event
      dispatch(
        createTask({
          ...event,
          start: moment()._d,
          end: event.end,
          minutes: getMinutesDifference(moment()._d, event.end),
          orderId: event.order._id,
          dragged: false,
        })
      );
    }
  };

  // ----

  const startEvent = (event) => {
    let new_dragged_event = {
      _id: event._id,
      task: event.task,
      allDay: false,
      start: event.start,
      end: event.end,
      order: event.order,
      minutes: event.minutes,
      client: event.client,
      planned: event.planned,
    };

    // Add to dragged events
    dispatch(addToDraggedEvents(new_dragged_event));

    // remove from undragged
    dispatch(removeFromUndraggedEvents(event._id));

    dispatch(updateTodoEvent(event._id, { start: event.start, end: event.end }));
  };

  const onSubmitTodo = (data) => {
    dispatch(createTask({ ...data, orderId: data.OrderId.value, planned: false }));
    setCalendarDates(false);
  };

  const handleEventAction = (event) => {
    setEventAction({ event: event, show: true });
  };

  const createTodoForCalendar = (event) => {
    setCalendarDates({
      dragged: true,
      start: event.start,
      end: event.end,
      minutes: getMinutesDifference(event.start, event.end),
    });
    setShowTaskForm(true);
  };

  const formats = {
    timeGutterFormat: "HH:mm",
    eventTimeRangeFormat: ({ start, end }, culture, local) => {
      return (
        local.format(start, "HH:mm", culture) +
        " - " +
        local.format(end, "HH:mm", culture)
      );
    },
    agendaTimeFormat: "HH:mm",
  };

  return (
    <>
      <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="flex gap-3 align-items-center">
              <span>Filtrer</span>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AnimatePresence initial={false} mode="wait">
            <form onSubmit={handleSubmit(onSubmitFilter)}>
              <Filter
                showGraphs={showGraphs}
                setShowModal={setShowModal}
                control={control}
                clientList={clientList}
                productList={productList}
                register={register}
                filtred_users={filtred_users}
                BOSS_MANAGER={BOSS || MANAGER}
              />
            </form>
          </AnimatePresence>
        </Offcanvas.Body>
      </Offcanvas>

      <Row className="mb-3">
        <Col md={12}>
          <div className="task-calendar-container">
            <UnDraggedEvents
              setShowTasks={setShowTasks}
              showTasks={showTasks}
              undraggedEvents={undraggedEvents}
              can_manage_calendar={can_manage_calendar}
              devideTask={devideTask}
              onCreateTodo={() => setShowTaskForm(true)}
              authorized={AUTHORIZED_MANAGER || currentUser.can_create_todo}
              height={height}
              startEvent={startEvent}
              calendarStep={calendarStep}
              setCalendarStep={setCalendarStep}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
            />

            <div
              className="calendar-container  rounded-3 bg-white"
              style={{ height: `calc(${height}px - 23px)` }}
            >
              <div
                className={`myCustomHeight gap-3 ${
                  loading ? "loading-effect blur" : ""
                } ${can_manage_calendar ? "" : "unauthorized"}`}
                onDragOver={(event) => event.preventDefault()}
                onDrop={handleDrop}
              >
                <DnDCalendar
                  popup
                  formats={formats}
                  selectable
                  step={calendarStep}
                  localizer={localizer}
                  events={draggedEvents}
                  onEventDrop={moveEvent}
                  dragFromOutsideItem={draggedEvent} // Pass the boolean value
                  onDropFromOutside={onDropFromOutside}
                  onDragOver={customOnDragOver}
                  resizable={can_manage_calendar}
                  onEventResize={resizeEvent}
                  min={allowedStartTime}
                  max={allowedEndTime}
                  dayPropGetter={dayPropGetter}
                  views={["work_week", "month"]}
                  defaultView={view}
                  onSelectEvent={handleEventAction}
                  onSelectSlot={createTodoForCalendar}
                  resourceIdAccessor="_id"
                  eventPropGetter={eventPropGetter}
                  titleAccessor={(event) =>
                    `${event.meet ? "📹 " : ""} ${event.client?.companyName}  -  ${
                      event.task
                    }`
                  }
                  messages={{
                    work_week: "Semaine",
                    day: "Jour",
                    month: "Mois",
                    previous: "Précédent",
                    next: "Suivant",
                    today: "Aujourd'hui",
                  }}
                  onRangeChange={(range) => {
                    setWeekDates({ start: range[0], end: moment(range[4]).endOf("day") });
                  }}
                  onView={(view) => {
                    setView(view);
                  }}
                  tooltipAccessor={(event) =>
                    event.client?.companyName + " - " + event.task
                  }
                  date={!clientId && !productId ? dates.start : undefined}
                  onNavigate={(newDate, view, action) => {
                    let startMoment = moment(dates.start);
                    let endMoment = moment(dates.end);

                    if (action === "NEXT") {
                      if (view === "work_week") {
                        setDates({
                          start: startMoment.add(7, "days").toDate(),
                          end: endMoment.add(7, "days").toDate(),
                        });
                      } else if (view === "month") {
                        setDates({
                          start: startMoment.add(1, "month").startOf("month").toDate(),
                          end: startMoment.endOf("month").toDate(), // Utilisez startMoment modifié pour calculer la fin du mois
                        });
                      }
                    } else if (action === "PREV") {
                      if (view === "work_week") {
                        setDates({
                          start: startMoment.subtract(7, "days").toDate(),
                          end: endMoment.subtract(7, "days").toDate(),
                        });
                      } else if (view === "month") {
                        setDates({
                          start: startMoment
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          end: startMoment.endOf("month").toDate(),
                        });
                      }
                    } else if (action === "TODAY") {
                      if (view === "work_week") {
                        setDates({
                          start: moment().startOf("week").toDate(),
                          end: moment().endOf("week").toDate(),
                        });
                      } else if (view === "month") {
                        setDates({
                          start: moment().startOf("month").toDate(),
                          end: moment().endOf("month").toDate(),
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className="py-1 custom-gray">
                <div className="flex gap-3 justify-content-between">
                  {draggedEvents &&
                    view === "work_week" &&
                    calendarUsers.map((user) => (
                      <div key={user._id} className="flex gap-3 workers-container">
                        <h6>
                          <span className="product-title bg-primary px-3 text-white">
                            {user.name}
                          </span>
                        </h6>
                        <h6>
                          {" "}
                          {week_work(user._id)} /{user.weekly_hours} (Heures)
                        </h6>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          {showModal && (
            <Chart
              rawData={draggedEvents}
              closeModal={() => setShowModal(false)}
              clientName={clientName}
            />
          )}
        </Col>
      </Row>

      {eventAction.event && (
        <EventAction
          show={eventAction.show}
          handleClose={() => setEventAction({ ...eventAction, show: false })}
          event={eventAction.event}
          onDeleteTodo={onDeleteTodo}
          onUndragTodo={onUndragTodo}
          onDoneTodo={onDoneTodo}
          onDuplicateTodo={onDuplicateTodo}
          onStartTodo={onStartTodo}
          onEndTodo={onEndTodo}
          onCutTodo={onCutTodo}
          onChangeTask={onChangeTask}
          onShareTodo={onShareTodo}
          employees={filtred_users}
        />
      )}
      <TaskForm
        show={showTaskForm}
        handleClose={() => setShowTaskForm(false)}
        onSubmitTodo={onSubmitTodo}
        clientList={clientList}
        clients={clients}
        products={products}
        currentUser={currentUser}
        calendarDates={calendarDates}
      />
    </>
  );
};

export default MyCalendar;
