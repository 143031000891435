import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const Note = ({ note, aria, areaNumber, id }) => {
  return (
    <>
      {note && note._id && (
        <div
          className="card p-3 mb-4 note-card shadow-none bg-white"
          key={note?._id}
          aria={aria}
          area-number={areaNumber}
          id={id}
          data-width="580"
        >
          <div className="flex justify-content-between">
            <div>
              <h6>
                <span>Explication de notre équipe</span>
              </h6>
              <div>{parse(note?.text)}</div>
            </div>
            <div className="text-end">
              <div>{note?.order?.product?.name}</div>
              <div>
                <small>{note?.createdBy?.name}</small>
              </div>
              <div>
                <small>{moment(note?.createdAt).format("DD-MM-YYYY")}</small>
              </div>
              <div>
                {note?.shared && (
                  <FontAwesomeIcon icon={faUsers} className="pe-2 purple-color" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Note;
