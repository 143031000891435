import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Offcanvas } from "react-bootstrap";
import moment from "moment";

const TaskForm = ({
  show,
  handleClose,
  onSubmitTodo,
  clientList,
  clients,
  products,
  currentUser,
  calendarDates,
}) => {
  const [orders, setOrders] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const clientId = watch("clientId");

  const getProduct = (productId) => {
    return products.find((product) => product._id === productId);
  };

  useEffect(() => {
    if (clientId) {
      const client = clients.filter((cl) => cl._id === clientId.value);
      const filtred_orders = client[0].orders.filter(
        (order) =>
          !order.orderCompleted &&
          (order.team1Responsible === currentUser._id ||
            order.team2Responsible === currentUser._id ||
            order.team3Responsible === currentUser._id ||
            order.team4Responsible === currentUser._id ||
            order.team5Responsible === currentUser._id ||
            currentUser.role === "manager")
      );
      setOrders(
        filtred_orders.map((order) => ({
          value: order._id,
          label: getProduct(order.product)?.name,
        }))
      );
    } else {
      setOrders([]);
    }
  }, [clients, clientId, setOrders]);

  useEffect(() => {
    if (calendarDates) {
      setValue("start", calendarDates.start);
      setValue("end", calendarDates.end);
      setValue("dragged", calendarDates.dragged);
      setValue("minutes", calendarDates.minutes);
    }
    //else reset (if user click on plus)
  }, [calendarDates]);

  const submitTodo = (data) => {
    onSubmitTodo(data);
    reset();
  };

  const FLEXIBILITY = [
    { FLZR: "Flexibilité Zéro" },
    { FLDY: "Flexibilité Journalière" },
    { FLHB: "Flexibilité Hebdomadaire" },
  ];

  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Ajouter une tâche</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit(submitTodo)}>
          <Row className="xmb-5">
            <input type="hidden" {...register("dragged")} defaultValue={false} />
            {calendarDates && (
              <>
                <Col md={6}>
                  <label htmlFor="clientId" className="form-label">
                    Début de la tâche :
                  </label>

                  <input
                    disabled
                    className="form-control"
                    value={moment(calendarDates.start).format("DD-MM-YYYY h:mm")}
                  />
                  <input type="hidden" {...register("start")} />
                </Col>
                <Col md={6}>
                  <label htmlFor="clientId" className="form-label">
                    Fin de la tâche :
                  </label>

                  <input
                    disabled
                    className="form-control"
                    value={moment(calendarDates.end).format("DD-MM-YYYY h:mm")}
                  />
                  <input type="hidden" {...register("end")} />
                </Col>
              </>
            )}

            <Col md={12}>
              <label htmlFor="clientId" className="form-label">
                Client :
              </label>
              <Controller
                name="clientId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    options={clientList}
                    isClearable={true}
                    placeholder="Sélectionnez un client"
                  />
                )}
              />
              {errors.clientId && <div className="text-danger">Champs obligatoire</div>}
            </Col>
            {clientId && (
              <Col md={12}>
                <label htmlFor="OrderId" className="form-label">
                  Commande :
                </label>
                <Controller
                  name="OrderId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={orders}
                      isClearable={true}
                      placeholder="Sélectionnez un produit"
                    />
                  )}
                />
              </Col>
            )}
            <Col md={12}>
              <label htmlFor="duration" className="form-label">
                Nouvelle tache :
              </label>
              <input
                type="text"
                className="form-control"
                id="duration"
                {...register("task", { required: true })}
              />
              {errors.task && <div className="text-danger">Champs obligatoire</div>}
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <label htmlFor="minutes" className="form-label">
                  Minutes :
                </label>
                <input
                  className="form-control"
                  id="minutes"
                  type="number"
                  min={15}
                  max={480}
                  step={15}
                  {...register("minutes", { required: true })}
                  disabled={calendarDates}
                />
                {errors.minutes && <div className="text-danger">Minutes requise</div>}
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <label htmlFor="minutes" className="form-label">
                  Flexibilité de la tâche :
                </label>
                <select
                  className="form-control"
                  id="role"
                  {...register("flexibility", { required: true })}
                  defaultValue="FLDY"
                >
                  {FLEXIBILITY.map((issue, index) => {
                    const key = Object.keys(issue)[0];
                    const value = Object.values(issue)[0];
                    return (
                      <option key={key} value={key} className={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>

                {errors.flexibility && (
                  <div className="text-danger">Le champ est requis</div>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="">
                <label className="form-label"></label>
                <button type="submit" className="btn bg-gradient-dark mt-1 w-100 mb-0">
                  Ajouter
                </button>
              </div>
            </Col>
          </Row>
        </form>
        <div className="task-container my-5">
          <div className="task-explanation py-2">
            <div>
              <span className="box-task todo-task FLHB"></span>
              <span>Flexibilité Hebdomadaire</span>
            </div>
            <div>
              <span className="box-task todo-task FLDY"></span>
              <span>Flexibilité Journalière</span>
            </div>
            <div>
              <span className="box-task todo-task FLZR"></span>
              <span>Flexibilité Zéro</span>
            </div>
            <div>
              <span className="box-task done-task"></span>
              <span>tâche réalisé</span>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TaskForm;
